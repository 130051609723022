<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <div class="row">
            <div class="col-md-12">
                <ADatePicker
                    class="mr-2"
                    v-model:value="state.params.transaction_date"
                    placeholder="Pilih Tanggal Transaksi"
                    style="width:300px"
                    format="DD MMMM YYYY"/>
                <ADatePicker
                    class="mr-2"
                    v-model:value="state.params.sync_date"
                    placeholder="Pilih Tanggal Sync"
                    style="width:300px"
                    format="DD MMMM YYYY"/>
                <ASelect
                    class="mr-2"
                    v-model:value="state.params.type_api"
                    placeholder="Pilih Jenis API"
                    style="width:300px"
                    :options="[{label: 'Manual', value: 'manual'}]">
                    </ASelect>
                <FilterDistributor
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.distributor"/>
                <AButton
                    class="mr-2"
                    type="primary"
                    title="cari"
                    @click="btnFetchData()"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </div>
        </div>

        <div class="row justify-content-end">
            <div class="col-lg-6 col-md-12 mt-auto">
            </div>
            <div class="col-lg-6 col-md-12 text-right mt-auto">
                <DownloadExcel
                    :url="state.endpoint"
                    :params="state.params"
                    namefile="Laporan-Toko-Aktif-LT"
                    @errors="errorMessage" />
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">
            </MdTable>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, watch, onBeforeMount } from 'vue'
import apiClient from '@/services/axios'
import FilterDistributor from '@/components/filter/FilterDistributor'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import { debounce, pickBy } from 'lodash'
import moment from 'moment'

export default defineComponent({
    components: {
        FilterDistributor,
        DownloadExcel,
    },
    setup() {
        const errorMessage = ref()

        const state = reactive({
            columns: [
                {
                    title: 'Tanggal Transaksi',
                    dataIndex: 'field',
                },
                {
                    title: 'Tanggal Sync',
                    dataIndex: 'field',
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'field',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'field',
                },
                {
                    title: 'Status',
                    dataIndex: 'field',
                },
                {
                    title: 'Fungsi API',
                    dataIndex: 'field',
                },
                {
                    title: 'Jenis API',
                    dataIndex: 'field',
                },
                {
                    title: 'Pesan Error',
                    dataIndex: 'field',
                },
                {
                    title: 'Message on DO',
                    dataIndex: 'field',
                },
                {
                    title: 'Response',
                    dataIndex: 'field',
                },
            ],
            endpoint: '/api/report/unknown',
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: {
                q: '',
                type_api: null,
                distributor: [],
                transaction_date: moment(new Date()).startOf('month'),
                sync_date: null,
                page: 1,
                "per-page": 10,
            },
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize
            
            btnFetchData()
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.transaction_date) {
                state.params.transaction_date = moment(state.params.transaction_date).format('YYYY-MM-DD')
            }

            if (state.params.sync_date) {
                state.params.sync_date = moment(state.params.sync_date).format('YYYY-MM-DD')
            }

            return pickBy(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get('/api/report/unknown', {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        const btnFetchData = () => {
            fetchDataList()
        }

        // handle vue
        onMounted(() => {
            btnFetchData()
        })

        return {
            btnFetchData,
            state,
            handleTableChange,
            errorMessage,
        }
    },
})
</script>

<style lang="scss" scoped>
.card {
    height: 100%;
    .card-body {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}
</style>